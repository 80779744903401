<template>
  <div>
    <v-container>
      <v-row v-for="(sh, id) of scheduling" :key="id" class="mt-2">
        <v-col cols="5" sm="5" md="5">
          <v-menu
            v-model="sh.menu1"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind:class="{ 'error-border': sh.hasError }"
                v-model="sh.start"
                hide-details
                label="Seleccionar fecha inicial"
                append-icon="fas fa-calendar"
                readonly
                clearable
                rounded
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="
                sh.menu1 = false;
                validation();
              "
              v-model="sh.start"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5" sm="5" md="5">
          <v-menu
            v-model="sh.menu2"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="sh.end"
                label="Seleccionar fecha final"
                append-icon="fas fa-calendar"
                readonly
                v-bind:class="{ 'error-border': sh.hasError }"
                hide-details
                clearable
                rounded
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              @input="
                sh.menu2 = false;
                validation();
              "
              v-model="sh.end"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- <v-col v-if="id + 1 == scheduling.length" cols="1" sm="1" md="1">
          
        </v-col> -->
        <v-col
          style="display: flex; justify-content: flex-start"
          cols="2"
          sm="2"
          md="2"
        >
          <v-btn
            @click="remove(id)"
            small
            class="mt-2"
            dark
            fab
            color="red darken-2"
          >
            <v-icon dark> fas fa-times </v-icon>
          </v-btn>
          <v-btn
            v-if="id + 1 == scheduling.length"
            @click="add"
            small
            class="mt-2 ml-3"
            dark
            fab
            color="primary"
          >
            <v-icon dark> fas fa-plus </v-icon>
          </v-btn>

          <v-tooltip bottom v-if="sh.hasError">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-on="on"
                v-bind="attrs"
                class="mt-2 ml-3"
                dark
                icon
                fab
                color="red darken-2"
              >
                <v-icon dark> fas fa-exclamation </v-icon>
              </v-btn>
            </template>
            <span>{{ sh.errorDetails }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "scheduling-c",
  props: ["originalScheduling"],
  data() {
    return {
      scheduling: [{}],
      menu: false,
      date: "",
    };
  },

  methods: {
    validation() {
      let isValid = true;

      for (let i = 0; i < this.scheduling.length; i++) {
        const schedule = this.scheduling[i];

        let hasError = false;

        if (!schedule.start || !schedule.end) {
          hasError = true;
          isValid = false;
          schedule.errorDetails = "Debe completar la fecha de inicial y final";
        }

        if (
          schedule.start &&
          schedule.end &&
          schedule.start.replace("-", "") > schedule.end.replace("-", "")
        ) {
          hasError = true;
          isValid = false;
          schedule.errorDetails =
            "La fecha inicial debe ser mayor que la fecha final";
        }

        schedule.hasError = hasError;
      }

      if (isValid) {
        let schedule = this.scheduling.map((e) => {
          return {
            start: e.start,
            end: e.end,
          };
        });

        this.$emit("updated", schedule);
      }
    },
    add() {
      this.scheduling.push({
        start: "",
        end: "",
      });
    },

    remove(index) {
      this.scheduling.splice(index, 1);
    },
  },

  mounted() {
    if (this.originalScheduling) {
      this.scheduling = Object.assign([], this.originalScheduling);
    }
  },
};
</script>

<style scoped>
.error-border {
  border: 1px solid red !important;
  /* background: #ff2e2e0d !important; */
}
</style>