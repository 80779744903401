<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Promociones</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          @change="getCities"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="selectedCity"
          :items="cities"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          @change="getCategories"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model="selectedCategory"
          :items="categories"
          placeholder="Escoge la categoría"
          outlined
          :disabled="!selectedCity"
          @change="getPromotions"
          item-text="name"
          item-value=".key"
          rounded
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text mr-5"
            @click="sortDialog = true"
            :loading="loading"
            :disabled="!selectedCategory"
          >
            <v-icon right dark class="mr-3">fas fa-bars</v-icon>
            Ordenar
          </v-btn>
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :disabled="!selectedCategory"
            @click="addPromo()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nueva promoción
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="promotions"
          :items-per-page="15"
          :search="$store.state.search"
          :loading="loading"
          item-key=".key"
          :sort-by="['position']"
          :sort-desc="[false, true]"
          :single-expand="true"
          fixed-header
          height="75vh"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.isShownInLobby`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isShownInLobby: item.isShownInLobby },
                  item['.key'],
                  item
                )
              "
              v-model="item.isShownInLobby"
            ></v-switch>
          </template>

          <template v-slot:[`item.isCategoryShown`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isCategoryShown: item.isCategoryShown },
                  item['.key'],
                  item
                )
              "
              v-model="item.isCategoryShown"
            ></v-switch>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.dateStart`]="{ item }">
            {{ item.dateStart | filterDate }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            {{ item.isFixed ? `${currency(item.amount)}` : `${item.amount} %` }}
          </template>

          <template v-slot:[`item.dateEnd`]="{ item }">
            {{ item.dateEnd | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editPromo(item)" small color="primary">Editar</v-btn>
            <v-btn
              @click="deletePromo(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
              >Eliminar</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      v-model="newPromoDialog"
      v-if="newPromoDialog"
      max-width="1100px"
    >
      <new-edit-promo
        :category="selectedCategory"
        :selectedItem="selectedItem"
        :position="promotions.length"
        @cancel="newPromoDialog = false"
        @success="handleCreated"
      ></new-edit-promo>
    </v-dialog>

    <v-dialog v-model="sortDialog" max-width="500px">
      <sort
        @cancel="sortDialog = false"
        @success="sortedSuccess"
        :category="selectedCategory"
        :promos="promotions"
      ></sort>
    </v-dialog>

    <v-dialog max-width="350" v-model="deleteDialog" v-if="deleteDialog">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="deleteDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ selectedItem.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import newEditPromo from "./new-edit-promo";
import sort from "./sort";

export default {
  name: "categories",
  components: {
    lottie: Lottie,
    newEditPromo,
    sort,
  },
  data() {
    return {
      loading: true,
      saving: false,
      countries: [],
      promotions: [],
      selectedCountry: "",
      selectedCity: "",
      selectedCategory: "",

      snackbar: false,
      snackbarText: "",
      selectedItem: null,
      deleteDialog: false,
      categories: [],
      cities: [],
      modalDelete: false,
      newPromoDialog: false,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      sortDialog: false,
      headers: [
        {
          text: "Posición",
          value: "position",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Descripción",
          value: "description",
        },
        // {
        //   text: "Veces que se ha visto",
        //   value: "counter",
        // },

        // {
        //   text: "Fecha inicio",
        //   value: "dateStart",
        // },
        // {
        //   text: "Fecha final",
        //   value: "dateEnd",
        // },
        {
          text: "Activo",
          value: "active",
        },
        // {
        //   text: "Mostrar en Lobby",
        //   value: "isShownInLobby",
        // },
        {
          text: "Creada",
          value: "createdAt",
        },
        {
          value: "options",
          align: "end",
          width: "200px",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  computed: {
    sortedCategories: function () {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return this.categories.sort(compare);
    },
  },
  methods: {
    sortByName(data) {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return data.sort(compare);
    },
    confirmDelete() {
      if (this.selectedItem) {
        db.collection(`categories/${this.selectedCategory}/promos`)
          .doc(this.selectedItem[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.deleteDialog = false;
            this.snackbar = true;
            this.snackbarText = "Promoción eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    deletePromo(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },

    handleCreated(msg) {
      this.newPromoDialog = false;
      this.snackbarText = this.selectedItem
        ? "Promoción actualizada correctamente."
        : "Promoción creada correctamente.";
      this.snackbar = true;
    },

    sortedSuccess() {
      this.sortDialog = false;
      this.snackbarText = "Promociones ordenadas correctamente.";
      this.snackbar = true;
    },

    switchControlChanged(data, promoId, promo) {
      if (data && promoId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.$store.state.user[".key"];
        this.saving = true;

        db.collection(`categories/${this.selectedCategory}/promos`)
          .doc(promoId)
          .update(data)
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            if (promo.hasOwnProperty("active")) promo.active = !promo.active;
            else if (promo.hasOwnProperty("isCategoryShown"))
              promo.isCategoryShown = !promo.isCategoryShown;
          });
      }
    },

    getCategories() {
      this.loading = true;
      this.categories = [];
      this.promotions = [];
      db.collection("categories")
        .where("deleted", "==", false)
        .where("city", "==", this.selectedCity)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let category = item.data();
            category[".key"] = item.id;
            this.categories.push(category);
          });

          this.categories = this.sortedCategories;
          this.loading = false;
        });
    },

    getCities(countryId) {
      this.categories = [];
      this.cities = [];
      this.selectedCity = "";
      this.promotions = [];
      this.selectedCategory = null;

      db.collection("cities")
        .where("deleted", "==", false)
        .where("countryId", "==", countryId)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let city = item.data();
            city[".key"] = item.id;
            this.cities.push(city);
          });

          this.cities = this.sortByName(this.cities);
        });
    },

    async getPromotions() {
      this.loading = true;
      await this.$binding(
        "promotions",
        db
          .collection(`categories/${this.selectedCategory}/promos`)
          .where("deleted", "==", false)
      );
      this.loading = false;
    },

    addPromo() {
      this.selectedItem = null;
      this.newPromoDialog = true;
    },
    editPromo(item) {
      this.selectedItem = item;
      this.newPromoDialog = true;
    },
  },

  mounted() {
    this.$store.commit("setSearchTerm", "promoción");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    db.collection("countries")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let country = item.data();
          country[".key"] = item.id;
          this.countries.push(country);
        });
        this.countries = this.sortByName(this.countries);
        this.loading = false;
      });
  },
};
</script>
